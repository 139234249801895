import React, { useEffect, useState } from "react";
import { navigate, Link } from "gatsby";
import { useFormik } from "formik";

import firebase from "./../utilities/firebase.js";
import Header from "../components/header";

const SignUp = () => {
  //const [brandId, setBrandId] = useState(false);
  const [brandName, setBrandName] = useState(false);
  const [wasInvited, setWasInvited] = useState(false);
  const [invitationId, setInvitationId] = useState(false);
  const [invitationProcessFinished, setInvitationProcessFinished] =
    useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationId = urlParams.get("invitationId");
    console.log("invitationId: ", invitationId);

    if (invitationId) {
      var cloudFunction = firebase.functions().httpsCallable("getInvitation");

      cloudFunction(invitationId)
        .then((result) => {
          console.log("RESULT: ", result);
          if (result.data.code === 200) {
            console.log("RESULT: ", result);
            setWasInvited(true);
            setInvitationId(invitationId);
            //setBrandId(result.data.brandId);
            setBrandName(result.data.brandName);
          }
          setInvitationProcessFinished(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setInvitationProcessFinished(true);
    }
  }, []);

  const validate = (values) => {
    console.log(values);
    console.log("first name: ", values.firstName);
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 200) {
      errors.firstName = "Must be 200 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 200) {
      errors.lastName = "Must be 200 characters or less";
    }

    if (!wasInvited) {
      if (!values.brandName) {
        errors.brandName = "Required";
      } else if (values.brandName.length > 200) {
        errors.brandName = "Must be 200 characters or less";
      }
    }

    if (!wasInvited) {
      if (!values.url) {
        errors.url = "Required";
      } else if (
        //!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
        !/^([-a-zA-Z0-9@:/%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*))?$/i.test(
          values.url
        )
      ) {
        errors.url = 'Invalid URL. Should be in the form "example.com"';
      }
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters or more";
    }

    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      brandName: "",
      url: "",
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      var signUp = firebase.functions().httpsCallable("signUp");
      console.log("invitationId: ", invitationId);
      setIsSigningUp(true);

      signUp({ formValues: values, invitationId: invitationId }).then(
        (result) => {
          console.log("result: ", result);
          firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((userCredential) => {
              console.log("userCredential: ", userCredential);
              // Signed in
              navigate("/app/dashboard");
            })
            .catch((error) => {
              //var errorCode = error.code;
              //var errorMessage = error.message;
              setIsSigningUp(false);
            });
        }
      );
    },
  });

  //auth.signOut()
  console.log("II");

  return !isSigningUp ? (
    invitationProcessFinished && (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="h-full flex flex-col justify-center lg:py-0 lg:pt-20">
          <div>
            <div className="cardContainerOuter max-w-xl mx-auto">
              <div className="cardContainerInner bg-gray-50">
                <div className="text-center mb-16">
                  <h2>Sign up</h2>
                  <p className="mt-2 text-base text-center">
                    {`Already a user? `}
                    <Link
                      to="/login"
                      className="font-medium text-coral-500 hover:text-coral-600"
                    >
                      Log in here
                    </Link>
                  </p>
                  {wasInvited && (
                    <p className="mt-8 text-center text-sm text-gray-600 max-w">
                      {`You are invited to represent ${brandName} and sell their product
              live on their webshop. Sign up to begin!`}
                    </p>
                  )}
                </div>

                <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="firstName">First name</label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          autoComplete="given-name"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          className={`${formik.errors.firstName && formik.touched.firstName
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formik.errors.firstName &&
                          formik.touched.firstName && (
                            <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                              {formik.errors.firstName}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="lastName">Last name</label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          autoComplete="family-name"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          className={`${formik.errors.lastName && formik.touched.lastName
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formik.errors.lastName && formik.touched.lastName && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formik.errors.lastName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="email">Email address</label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className={`${formik.errors.email && formik.touched.email
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                          {formik.errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  {!wasInvited && (
                    <div className="col-span-6">
                      <label htmlFor="brandName">Brand / Business name</label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="brandName"
                          id="brandName"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.brandName}
                          className={`${formik.errors.brandName && formik.touched.brandName
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formik.errors.brandName &&
                          formik.touched.brandName && (
                            <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                              {formik.errors.brandName}
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  {!wasInvited && (
                    <div className="col-span-6">
                      <label htmlFor="url">Website address</label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="url"
                          id="url"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.url}
                          className={`${formik.errors.url && formik.touched.url
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formik.errors.url && formik.touched.url && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formik.errors.url}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-span-6">
                    <label htmlFor="password">Password</label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className={`${formik.errors.password && formik.touched.password
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                          {formik.errors.password}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="primary-button">
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div className="flex justify-center items-center h-screen">
      <svg
        className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {/*<p className="ml-2">Loading...</p>*/}
    </div>
  );
};

export default SignUp;
